<template>
    <div>
        <div class="-z-1 relative">
            <!-- Full-width image container with text overlay -->
            <div class="relative left-1/2 right-1/2 -mx-[50vw] w-screen overflow-hidden md:-mt-20">
                <!-- Full-width image -->
                <HomeBannerCarousel class="z-0" />

                <!-- Full-width banner under image -->
                <div class="relative left-1/2 right-1/2 z-10 -mx-[50vw] w-screen bg-blue-demegro">
                    <div
                        class="mx-auto flex max-w-screen-xl flex-col items-center justify-between px-4 py-4 text-white md:h-[71px] md:flex-row md:px-0 md:py-0"
                    >
                        <div class="mb-2 flex items-center px-4 md:mb-0">
                            <font-awesome-icon
                                :icon="['fas', 'badge-check']"
                                class="mr-2 text-[23px] text-lime-demegro"
                            />
                            <span class="text-sm md:text-base">Ruim 15.000 artikelen</span>
                        </div>
                        <div class="mb-2 flex items-center px-4 md:mb-0">
                            <font-awesome-icon
                                :icon="['fas', 'badge-check']"
                                class="mr-2 text-[23px] text-lime-demegro"
                            />
                            <span class="text-sm md:text-base">Gratis verzending vanaf €99,-</span>
                        </div>
                        <div class="flex items-center px-4">
                            <font-awesome-icon
                                :icon="['fas', 'badge-check']"
                                class="mr-2 text-[23px] text-lime-demegro"
                            />
                            <span class="text-sm md:text-base">Bel voor persoonlijke service naar: 0485 - 318 570</span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Start content -->
            <div class="content-container my-[96px] space-y-[96px]">
                <!-- Articles Banner -->
                <div>
                    <h2 class="homepage_title">
                        Actuele aanbiedingen
                    </h2>
                    <HomeArticleBanner />
                </div>

                <!-- Information blocks -->
                <div class="flex max-h-[369px] space-x-[30px]">
                    <div class="relative h-[390px] w-[57%]">
                        <NuxtImg
                            src="scan_go_setup.webp"
                            class="rounded-block h-full w-full object-cover object-center"
                        />
                        <div class="absolute inset-0 flex">
                            <div class="flex w-2/5 flex-col justify-center p-8 text-blue-demegro">
                                <h2 class="text-[38px] font-black">
                                    Scan & Go!
                                </h2>
                                <p class="mt-2 text-xl">
                                    Solide voorraadbeheer; nooit meer misgrijpen
                                </p>
                                <NuxtLink to="https://handleiding.demegro.nl/">
                                    <button
                                        class="mt-9 inline-flex w-fit rounded bg-blue-demegro px-4 py-2 text-white underline underline-offset-4"
                                    >
                                        Meer informatie
                                    </button>
                                </NuxtLink>
                            </div>
                        </div>
                    </div>
                    <div class="w-[43%] space-y-[22px]">
                        <!-- Dark blue no risk block -->
                        <div class="block-md bg-blue-demegro">
                            <div class="flex h-full w-[60%] flex-col text-base">
                                <h5 class="text-2xl font-black">
                                    Demedent, niet goed = geld terug
                                </h5>
                                <p>Bevalt Demedent product niet? Gewoon uw geld terug!</p>
                                <NuxtLink
                                    :to="{
                                        path: '/zoeken/product',
                                        query: { q: 'Demedent' },
                                    }"
                                    class="mt-auto font-bold underline underline-offset-4"
                                >
                                    Bestel direct
                                </NuxtLink>
                            </div>
                            <NuxtImg src="no_risk_sticker.webp" class="rounded-block ml-14 h-full -rotate-12" />
                        </div>

                        <!-- Light blue repairs block -->
                        <div class="block-md bg-blue-demegro-light">
                            <div class="mr-5 flex h-full w-[60%] flex-col text-base">
                                <h5 class="text-2xl font-black">
                                    Reparatie
                                </h5>
                                <p>Wij repareren alle hand- en hoekstukken, turbines, micromotoren en koppelingen.</p>
                                <NuxtLink to="/reparatie" class="mt-auto font-bold underline underline-offset-4">
                                    Vraag een reparatie aan
                                </NuxtLink>
                            </div>
                            <NuxtImg src="cogs.png" class="rounded-block ml-14 h-full" />
                        </div>
                    </div>
                </div>

                <!-- More products -->
                <div class="">
                    <div class="flex justify-between">
                        <h2 class="homepage_title">
                            Onze huismerk
                        </h2>
                        <NuxtLink to="/zoeken/product?q=Demedent" class="text-lg text-blue-demegro underline underline-offset-4">
                            Bekijk al onze huismerk artikelen
                        </NuxtLink>
                    </div>
                    <HomeDemedentArticlesList />
                </div>

                <!-- Company description and reasons blocks -->
                <div class="flex flex-row space-x-14">
                    <div class="w-1/2">
                        <h5 class="mb-4 text-[28px] font-bold">
                            Demegro, persoonlijk en voordelig
                        </h5>
                        <p class="text-base leading-7">
                            Demegro bewijst al meer dan 15 jaar aan onze klanten een betrouwbare Dental Depot te zijn
                            met een uitgebreid assortiment van ruim 15.000 artikelen. Wij geloven dat wij u de beste
                            prijs in de markt kunnen geven, zonder het persoonlijk contact uit het oog te verliezen. Dit
                            realiseren wij door dagelijks de concurrenten scherp in de gaten te houden en de korte
                            communicatielijnen binnen de organisatie.
                        </p>
                    </div>
                    <div class="w-1/2">
                        <h5 class="mb-4 text-[28px] font-bold">
                            Waarom Demegro?
                        </h5>
                        <ul class="text-base leading-7">
                            <li class="flex items-start">
                                <font-awesome-icon :icon="['fas', 'circle']" class="bullet-point" />
                                <span>Ruim 15 jaar ervaring</span>
                            </li>
                            <li class="flex items-start">
                                <font-awesome-icon :icon="['fas', 'circle']" class="bullet-point" />
                                <span>Scherpe prijsstelling</span>
                            </li>
                            <li class="flex items-start">
                                <font-awesome-icon :icon="['fas', 'circle']" class="bullet-point" />
                                <span>Persoonlijk contact met deskundig personeel dat met u meedenkt</span>
                            </li>
                            <li class="flex items-start">
                                <font-awesome-icon :icon="['fas', 'circle']" class="bullet-point" />
                                <span>Uitgebreid assortiment, alle A-merken en voordelige alternatieven</span>
                            </li>
                            <li class="flex items-start">
                                <font-awesome-icon :icon="['fas', 'circle']" class="bullet-point" />
                                <span>Eenvoudig en snel betalen op uw manier</span>
                            </li>
                            <li class="flex items-start">
                                <font-awesome-icon :icon="['fas', 'circle']" class="bullet-point" />
                                <span>Gratis verzending vanaf € 99,-</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- End content -->
        </div>
    </div>
</template>

<style scoped>
.rounded-block {
    border-radius: 10px;
}

.block-md {
    @apply rounded-block flex h-1/2 flex-row justify-between p-[19px] text-white;
}

.bullet-point {
    @apply mr-6 mt-2 size-2.5 flex-shrink-0 text-blue-demegro;
}

.duotone-cog-icon {
    --fa-secondary-color: red;
    --fa-primary-color: blue;
}

.icon-full {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
}

.homepage_title {
    @apply mb-[34px] text-2.5xl font-bold text-black-demegro dark:text-gray-demegro-light;
}
</style>
