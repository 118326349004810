<template>
    <div class="flex h-[276px] items-center justify-center p-5 transition-all duration-300">
        <DPicture
            v-if="image"
            :src="image?.src"
            loading="lazy"
            :alt="article.title"
            class="w-3/4"
            :class="{ 'absolute inset-5 animate-pulse rounded-lg bg-gray-100': article._skeleton }"
        />
    </div>

    <!-- Title Container -->
    <div class="flex flex-grow items-top justify-center px-6 pb-6 text-center">
        <span
            class="block text-base font-bold transition-all duration-300"
            :class="[
                article._skeleton
                    ? 'h-6 w-3/4 animate-pulse rounded-md bg-gray-100'
                    : 'text-black-demegro hover:text-blue-demegro',
            ]"
        >
            {{ article.title }}
        </span>
    </div>
</template>

<script setup lang="ts">
import type { Article } from '@/types/demegro'
import type { BaseSkeletonObject } from '~/composables/useSkeletonLoader'

const props = defineProps<{
    article: Article & BaseSkeletonObject
}>()

const image = computed(() => useArticle().getImage(props.article))
</script>
