<template>
    <div class="relative w-screen">
        <Carousel
            v-model="currentSlide"
            :wrap-around="true"
            :autoplay="6000"
            :transition="300"
            :pause-autoplay-on-hover="true"
            class="w-full"
        >
            <Slide v-for="(banner, index) in slides" :key="index">
                <div class="relative w-full bg-white">
                    <div class="mx-auto">
                        <NuxtImg :src="banner.image" class="h-[650px] w-full object-cover" />
                    </div>
                    <div class="absolute left-1/2 top-0 z-10 h-full w-full max-w-screen-xl -translate-x-1/2 text-left">
                        <div class="pl-4! md:pl-0! absolute left-0 top-0 flex h-full w-[55%] flex-col justify-center bg-white/70 md:bg-transparent">
                            <h2 class="mb-4 text-4xl font-black leading-tight md:text-[53px]">
                                {{ banner.header_text }}
                            </h2>
                            <p class="text-2xl md:text-lg">
                                {{ banner.body_text }}
                            </p>
                        </div>
                    </div>
                </div>
            </Slide>
        </Carousel>
        <button class="carousel-control prev z-50" @click="goToPrev">
            <span class="sr-only">Vorige</span>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="h-6 w-6"
            >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
            </svg>
        </button>
        <button class="carousel-control next z-50" @click="goToNext">
            <span class="sr-only">Volgende</span>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="h-6 w-6"
            >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
            </svg>
        </button>
    </div>
</template>

<script setup lang="ts">
import { Carousel, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'

interface Banner {
    image: string
    header_text: string
    body_text: string
}

const currentSlide = ref(0)
const { data: slides } = await useFetch<Banner[]>('https://api.demegro.nl/api/websitecontent/contentheaders', {
    server: true,
    default: () => [],
    key: Date.now().toString(),
})

function goToPrev() {
    currentSlide.value = currentSlide.value > 0 ? currentSlide.value - 1 : slides.value.length - 1
}

function goToNext() {
    currentSlide.value = currentSlide.value < slides.value.length - 1 ? currentSlide.value + 1 : 0
}
</script>

<style scoped>
.carousel-control {
    @apply absolute top-1/2 -translate-y-1/2 transform rounded-full bg-white p-2 shadow-md;
}

.carousel-control.prev {
    @apply left-4;
}

.carousel-control.next {
    @apply right-4;
}

.carousel-control svg {
    @apply text-gray-700 hover:text-gray-900;
}
</style>
