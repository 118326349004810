<template>
    <div
        class="flex h-[320px] flex-col space-y-5 rounded-lg bg-white p-4 transition-colors duration-300 dark:bg-gray-800"
    >
        <div class="flex h-3/6 items-center justify-center">
            <NuxtLink :to="article.slug" class="flex grow items-center justify-center">
                <DPicture
                    :src="image?.src"
                    class="h-full w-[60%] object-contain"
                    loading="lazy"
                />
                <template
                    v-if="
                        article.is_demedent
                            || article.manufacturer_name?.toUpperCase() === 'DEMEDENT'
                    "
                >
                    <NuxtImg
                        src="/no_risk_sticker.webp"
                        class="absolute right-2 top-2 z-10 size-20 -rotate-[13deg]"
                    />
                </template>
            </NuxtLink>
        </div>
        <div class="flex h-3/6 flex-col justify-between">
            <NuxtLink :to="article.slug">
                <p class="truncate text-left text-xs text-gray-demegro dark:text-zinc-400">
                    {{ article.manufacturer_name }}
                </p>
                <h3
                    class="line-clamp-2 text-left font-bold line-height-sm text-gray-800 dark:text-gray-demegro-light"
                >
                    {{ article.title }}
                </h3>
            </NuxtLink>
            <div
                class="flex shrink-0 justify-between text-lime-demegro dark:text-dark-lime-demegro"
            >
                <span class="text-base">
                    {{ authenticated ? 'Nu vanaf' : 'Prijs' }}
                </span>
                <span
                    :class="{
                        'text-[22px] font-black': authenticated,
                        'text-base font-medium italic': !authenticated,
                    }"
                >
                    {{
                        authenticated
                            ? article.price
                                ? `€ ${Number(article.price).toFixed(2).replace('.', ',')}`
                                : 'Prijs op aanvraag'
                            : 'Log in voor uw prijs'
                    }}
                </span>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { Article } from '~/types/demegro'

const props = defineProps<{
    article: Article
}>()

const authenticated = toRef(useAuthStore(), 'authenticated')

const image = computed(() => useArticle().getImage(props.article))
</script>
